import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Answers, Question } from '../../../../types';
import QuestionMedia from './QuestionMedia';

type SimpleAnswerPanelProps = {
  question: Question;
  setAnswers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent: boolean;
};


const SimpleAnswerPanel = (props: SimpleAnswerPanelProps) => {
  const { question, setAnswers, answers, isCurrent } = props;
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    if (answers[question.id] && textValue !== answers[question.id]) {
      setTextValue(answers[question.id]);
    }
  }, [question]);

  useEffect(() => {
    setAnswers(question.id, textValue);
  }, [textValue]);

  return (
    <>
      <Typography component="div" sx={{ width: '100%', mb: 1 }}>
        <Markdown>{question.sa_question.sa_question_text}</Markdown>
      </Typography>

      <QuestionMedia
        mediaType={question.sa_question.question_media_type}
        mediaUrl={question.sa_question.question_media}
      />

      <TextField
        fullWidth
        data-cy={isCurrent ? 'answer-text' : ''}
        variant="standard"
        type={question.sa_question.ans_type}
        onChange={(e) => setTextValue(e.target.value)}
        value={textValue}
        InputProps={{
          autoFocus: isCurrent
        }}
      />
    </>
  );
};

export default SimpleAnswerPanel;