import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  CircularProgress,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { CloudOff, ErrorOutline, ArrowBack, OpenInNew } from '@mui/icons-material';

const ErrorFallback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { resetBoundary } = useErrorBoundary();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isRetrying, setIsRetrying] = useState(false);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleGoBack = () => {
    const canGoBack = window.history.length > 1;
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
    setTimeout(resetBoundary,1000)
  };

  const handleRetry = () => {
    setIsRetrying(true);
    setTimeout(() => {
      resetBoundary();
      setIsRetrying(false);
    }, 2000);
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <Box
        role="alert"
        sx={{
          width: '100%',
          textAlign: 'center',
          p: 4,
          borderRadius: 2,
          backgroundColor: 'background.paper',
          boxShadow: 3
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 4,
          color: isOnline ? theme.palette.warning.main : theme.palette.error.main
        }}>
          {isOnline ? (
            <ErrorOutline sx={{ fontSize: 80, opacity: 0.9 }} />
          ) : (
            <CloudOff sx={{ fontSize: 80, opacity: 0.9 }} />
          )}
        </Box>

        <Typography variant="h4" component="h1" sx={{ 
          mb: 2,
          fontWeight: 600,
          color: 'text.primary'
        }}>
          {isOnline ? t('Application Error') : t('Connection Lost')}
        </Typography>

        <Typography variant="body1" sx={{ 
          mb: 4,
          color: 'text.secondary',
          fontSize: '1.1rem',
          lineHeight: 1.6
        }}>
          {isOnline ? (
            t('An unexpected error occurred. Our technical team has been notified.')
          ) : (
            t('Network connection required. Please check your internet settings.')
          )}
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          justifyContent: 'center',
          '& > *': { width: 200 }
        }}>
          {isOnline ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
                size="large"
                startIcon={<ArrowBack />}
              >
                {t('Go Back')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                href="https://greybox.atlassian.net/servicedesk/customer/portals"
                target="_blank"
                rel="noopener noreferrer"
                size="large"
                startIcon={<OpenInNew />}
              >
                {t('Support Portal')}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetry}
              disabled={isRetrying}
              size="large"
              startIcon={isRetrying ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isRetrying ? t('Connecting...') : t('Retry Connection')}
            </Button>
          )}
        </Box>

        {!isOnline && (
          <Typography variant="caption" sx={{
            mt: 3,
            display: 'block',
            color: 'text.disabled'
          }}>
            {t('Attempting to reconnect...')}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default ErrorFallback;