import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Fade, Link, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { greyboxApiActions } from '../../../../redux/api';
import { RootState } from '../../../../redux/store';
import { Answers, Question } from '../../../../types';
import { getMediaTypeFromFileType } from '../../../../utils';
import FeedbackMessage from './FeedbackMessage';
import { parseAnswerText } from './utils';
import { LuPartyPopper } from 'react-icons/lu';

const answerParser = async (
  type: string,
  answer: string | File | null,
  sendMedia: (arg0: { body: FormData }) => any
) => {
  if (answer) {
    if (type === 'TF') return answer === 'T';
    if (type === 'MA') {
      if (answer instanceof File) {
        const newMedia = new FormData();
        let mediaType = getMediaTypeFromFileType(answer.type);
        newMedia.append('file', answer);
        newMedia.append('type', mediaType);
        const mediaData = await sendMedia({ body: newMedia });
        return mediaData.data.id;
      }
    }
  }
  return answer;
};

const questionIdParser = (question: Question) => {
  const type = question.type_of_q;
  const typeMap = {
    MC: 'mc_question',
    TF: 'tf_question',
    QA: 'qa_question',
    RA: 'ra_question',
    SA: 'sa_question',
    MA: 'ma_question',
  };
  return question[typeMap[type]].id;
};

const ThankYouMessage = ({ onComplete, res }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (onComplete) {
      const start = Date.now();
      const duration = 2000;

      const timer = setInterval(() => {
        const elapsed = Date.now() - start;
        setProgress(Math.min((elapsed / duration) * 100, 100));
      }, 50);

      const timeoutId = setTimeout(() => {
        clearInterval(timer);
        onComplete(res);
      }, duration);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(timer);
      };
    }
  }, [onComplete, res]);

  return (
    <Fade in timeout={1000}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h6">{t('Thank you for completing the questionnaire')}</Typography>
        {!onComplete && <Typography>{t('You can safely close this window')}</Typography>}
      </Box>
    </Fade>
  );
};

const QuestionBox = ({ question, children, handleBackFromSummary }) => (
  <Box sx={{ p: 2, border: (theme) => `2px dashed ${theme.palette.divider}`, m: 1, borderRadius: 1 }}>
    <Box display="flex" alignItems="center" minHeight="40px">
      <Link onClick={() => handleBackFromSummary(question.index)} sx={{ cursor: 'pointer' }}>
        <Typography variant="h6">{question.text}</Typography>
      </Link>
      <KeyboardReturnIcon sx={{ ml: 1 }} />
    </Box>
    {children}
  </Box>
);

type CompletionPanelProps = {
  patientUuid: string;
  tokenId: string;
  questions: Question[];
  answers: Answers;
  others: Answers;
  handleBackFromSummary: (index: number) => void;
  onComplete: (res: string) => void;
};

const CompletionPanel: React.FC<CompletionPanelProps> = ({
  questions,
  answers,
  handleBackFromSummary,
  onComplete,
  others,
  patientUuid = null,
  tokenId = null,
}) => {
  const { t } = useTranslation();
  const [openMessage, setOpenMessage] = useState(null);
  const [date, setDate] = useState(new Date());
  const location = useLocation();
  const { questionnaireAnswers, media } = greyboxApiActions;
  const [sendMedia] = media.add();
  const [addAnswer, { isLoading }] = questionnaireAnswers.add();
  const { access } = useSelector((state: RootState) => state.user);
  const [feedback, setFeedback] = useState(null);
  const [submissionError, setSubmissionError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmission = useCallback(async () => {
    try {
      setSubmissionError('');
      setIsSubmitting(true);

      // Validate required questions
      const requiredQuestions = questions.filter(q => q.required);
      const missingAnswers = requiredQuestions.filter(q =>
        !answers[q.id] || (typeof answers[q.id] === 'string' && !answers[q.id].trim())
      );

      if (missingAnswers.length > 0) {
        setSubmissionError(t('Please complete all required questions'));
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }

      if (location.pathname.includes('clinic-settings')) {
        setOpenMessage(true);
        return;
      }

      // Process answers with error handling
      const answerPromises = questions.map(async (question) => {
        try {
          const type = question.type_of_q;
          const answer = await answerParser(type, answers[question.id], sendMedia);

          return {
            base_question: question.id,
            question: questionIdParser(question),
            answer: answer,
            type: type,
            other_answer: others[question.id],
          };
        } catch (error) {
          console.error(`Error processing answer for question ${question.id}:`, error);
          throw new Error(t('Error processing media upload'));
        }
      });

      const parsedAnswers = (await Promise.all(answerPromises))
        .filter(answer => answer.answer !== '' && answer.answer !== null);

      const params = {
        questionnaire: questions[0].questionnaire,
        answer_timestamp: date,
        answers: parsedAnswers,
        ...(patientUuid && { patient: patientUuid }),
        ...(tokenId && { token: tokenId }),
      };

      const res = await addAnswer({ body: params });

      if (res.error) {
        throw new Error(res.error.message || t('Submission failed'));
      }

      res.data.feedback ? setFeedback(res.data) : setOpenMessage(res.data.id.toString());

    } catch (error) {
      console.error('Submission error:', error);
      setSubmissionError(error.message || t('An error occurred during submission'));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setIsSubmitting(false); // Stop loading in all cases
    }
  }, [questions, answers, others, date, patientUuid, tokenId, addAnswer, location, t, sendMedia]);


  const getMediaComponent = useCallback((media: File, answerType: string) => {
    const mediaUrl = URL.createObjectURL(media);

    useEffect(() => {
      return () => {
        if (media instanceof File) {
          URL.revokeObjectURL(mediaUrl);
        }
      };
    }, [media]);

    switch (answerType) {
      case 'image':
      case 'annotated_image':
        return <img src={mediaUrl} alt={t('Uploaded content')} style={{ maxWidth: '100%', maxHeight: 300 }} />;
      case 'audio':
        return (
          <audio controls style={{ width: '100%' }}>
            <source src={mediaUrl} />
            {t('Your browser does not support audio playback')}
          </audio>
        );
      case 'video':
        return (
          <video controls style={{ maxWidth: '100%', maxHeight: 300 }}>
            <source src={mediaUrl} />
            {t('Your browser does not support video playback')}
          </video>
        );
      default:
        return (
          <Button
            variant="contained"
            href={mediaUrl}
            target="_blank"
            download
            rel="noopener noreferrer"
          >
            {t('Download File')}
          </Button>
        );
    }
  }, [t]);

  if (submissionError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error" onClose={() => setSubmissionError('')}>
          {submissionError}
        </Alert>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <LoadingButton variant="contained" onClick={handleSubmission}>
            {t('Try Again')}
          </LoadingButton>
        </Box>
      </Box>
    );
  }

  if (openMessage) return <ThankYouMessage onComplete={onComplete} res={openMessage} />;
  if (feedback) return <FeedbackMessage handleClose={onComplete} data={feedback} />;

  const hasValidAnswer = (answer: any) => {
    if (typeof answer === "string") {
      return answer.trim().length > 0;
    }
    return answer !== undefined && answer !== null;
  };

  return (
    <Box sx={{ height: '100%', p: 2, mt: 2, width: '100%', maxWidth: 'lg' }}>
      <Typography sx={{ ml: 1 }}>
        <b>{`${t('Review your answers below')}. ${t('Click on any question to edit your response')}.`}</b>
      </Typography>

      {questions
        .filter(q => hasValidAnswer(answers[q.id]))
        .map((question, index) => (
          <AnswerSummary
            key={question.id}
            questions={questions}
            question={question}
            answers={answers}
            others={others}
            handleBackFromSummary={handleBackFromSummary}
            index={index}
            getMediaComponent={getMediaComponent}
          />
        ))}

      <Box
        display="flex"
        alignItems="center"
        justifyContent={{ xs: 'flex-start', sm: 'flex-end' }} // Responsive alignment
        flexWrap="wrap-reverse" // Allows wrapping on small screens
        gap={2} // Modern spacing API
        p={2}
        sx={{
          // Handle dense layouts
          '& .MuiFormControl-root': { // Target date picker
            minWidth: 250 // Prevent date picker from becoming too narrow
          }
        }}
      >
        {access === 'PT' && (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={t('Date of completion')}
              value={moment(date)}
              onChange={setDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        )}

        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          loadingPosition="start"
          startIcon={<SendIcon />}
          onClick={handleSubmission}
        >
          {isSubmitting ? t('Submitting...') : t('Submit')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

type AnswerSummaryProps = {
  question: Question;
  questions: Question[];
  answers: Answers;
  others: Answers;
  handleBackFromSummary: (index: number) => void;
  index: number;
  getMediaComponent: (media: File, answerType: string) => JSX.Element;
};

const AnswerSummary: React.FC<AnswerSummaryProps> = ({
  question,
  answers,
  handleBackFromSummary,
  index,
  others,
  questions,
  getMediaComponent,
}) => {
  const { t } = useTranslation();
  const type = question.type_of_q;

  const renderAnswerContent = () => {
    switch (type) {
      case 'MC':
        return (
          <ul>
            {answers[question.id].split(',').map((answer) => (
              <li key={answer}>
                <Typography>
                  {answer === 'OTHER'
                    ? others[question.id]
                    : parseAnswerText(
                      question.mc_question[`mc_question_choice_${answer.toLowerCase()}`],
                      questions,
                      answers
                    )}
                </Typography>
              </li>
            ))}
          </ul>
        );

      case 'TF':
        return <Typography>{answers[question.id] === 'T' ? t('Yes') : t('No')}</Typography>;

      case 'RA':
      case 'SA':
        return <Typography>{answers[question.id]}</Typography>;

      case 'MA':
        return getMediaComponent(answers[question.id], question.ma_question?.answer_type);

      default:
        return null;
    }
  };

  return (
    <QuestionBox
      handleBackFromSummary={handleBackFromSummary}
      question={{
        text: question[`${type.toLowerCase()}_question`][`${type.toLowerCase()}_question_text`],
        index,
      }}
    >
      {renderAnswerContent()}
    </QuestionBox>
  );
};

export default CompletionPanel;