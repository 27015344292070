import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import QuestionnaireAnswersFullscreen from './QuestionnaireAnswersFullscreen';

const isHex = (color) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

const colorMapping = (colorName) => {
  switch (colorName) {
    case 'Red':
      return 'error';
    case 'Yellow':
      return 'warning';
    case 'Green':
      return 'success';
    case 'Blue':
      return 'info';
    default:
      return 'info';
  }
};

const useStyles = makeStyles(() => ({
  custom: ({ color }) => ({
    borderColor: color,
    color: color,
  }),
}));

/**
 * Display a color coded status of selected questionnaire or alert.\
 * Default is:
 * - red = not feeling well
 * - green = feeling well
 * - gray = outdated
 * Will display all questions with their answers on click.
 */
const QuestionnaireSummaryButton = (props) => {
  const { i18n, t } = useTranslation();
  const {
    recordId, date, score, recordIndex, hasScore,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = score && score.display ? useStyles(score.display[0]) : useStyles();
  let className = '';
  let color = 'secondary';
  let text = '';
  let logo = null;
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  if (!hasScore || score === null) {
    if (location.pathname.includes('/dashboard')) {
      text = moment(date).format('ll');
    } else {
      text = t('Show answers');
    }
  } else if (score?.display) {
    text = score.display[0].value[i18n.resolvedLanguage] || '---';
    if (isHex(score.display[0].color)) {
      className = classes.custom;
    } else {
      color = colorMapping(score.display[0].color);
    }
  } else if (score?.threshold_status) {
    text = moment(date).format('ll');
    logo = <WarningIcon />;
    color = 'Range_1' in score.threshold_status ? 'error' : 'Range_2' in score.threshold_status ? 'warning' : 'info';
  } else {
    text = moment(date).format('ll');
    logo = <CheckCircleOutlineIcon />;
    color = 'success';
  }

  return (
    <>
      <Button
        className={className}
        color={color}
        variant="outlined"
        startIcon={logo}
        size="small"
        onClick={(event) => handleClick(event)}
      >
        {text}
      </Button>
      {open && (
        <QuestionnaireAnswersFullscreen
          recordId={recordId}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

QuestionnaireSummaryButton.propTypes = {
  recordId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  score: PropTypes.object,
  recordIndex: PropTypes.number,
  hasScore: PropTypes.bool.isRequired,
};

export default QuestionnaireSummaryButton;
