import { Box, GlobalStyles, Typography } from '@mui/material';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../utils';
import AnswerFormatter from './AnswerFormatter';

const PrintableAnswers = forwardRef(({ answers, questionnaire, patient }, ref) => {
  const { t, i18n } = useTranslation();

  if (!answers || !questionnaire || !patient) return null;

  return (
    <>
      <GlobalStyles styles={{
        '@media print': {
          '@page': {
            'size': 'auto',
            'margin': '2cm 1.5cm',
          },
          'body': { 
            '-webkit-print-color-adjust': 'exact' 
          }
        }
      }} />

      <Box sx={{
        p: 4,
        '@media print': {
          padding: '0 1cm',
          '& *': {
            color: '#000 !important',
            backgroundColor: 'transparent !important',
            fontSize: '0.875rem',
            lineHeight: 1.2
          }
        }
      }} ref={ref}>
        {/* Compact Header */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          '@media print': {
            flexDirection: 'column',
            gap: 0.5,
            marginBottom: '0.5cm'
          }
        }}>
          <Typography variant="caption" component="div">
            {moment(answers.answer_timestamp).format('lll')}
            {(answers.created_by && patient.user.id !== answers.created_by?.id) && (
              <div>
                {t('By')}: {answers.created_by.first_name} {answers.created_by.last_name}
              </div>
            )}
          </Typography>

          {/* Compact Patient Info */}
          <Box sx={{ 
            display: 'grid',
            gap: 0.5,
            '@media print': {
              borderBottom: '0.5px solid #ddd',
              paddingBottom: '0.25cm'
            }
          }}>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {`${patient.firstName} ${patient.lastName}`}
            </Typography>
            {patient.birthDate && (
              <Typography variant="caption" component="div">
                {`${t('Birth Date')}: ${moment(patient.birthDate).format('ll')}`}
              </Typography>
            )}
            {patient.phoneNumber && (
              <Typography variant="caption" component="div">
                {`${t('Phone')}: ${formatPhoneNumber(patient.phoneNumber)}`}
              </Typography>
            )}
            {patient.hin?.hin_number && (
              <Typography variant="caption" component="div">
                {`${t('Health Insurance Number')}: ${patient.hin.hin_number}`}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Main Content */}
        <Box sx={{ 
          '@media print': {
            '& > div': {
              padding: '0.25cm 0 !important',
              borderBottom: '0.5px solid #eee',
              '&:last-child': { border: 0 }
            }
          }
        }}>
          {questionnaire.questions.map((question) => (
            <AnswerFormatter
              key={question.id}
              question={question}
              data={answers}
              sx={{
                '@media print': {
                  padding: '0.25cm 0 !important',
                  '& .MuiBox-root': { padding: '0 !important' }
                }
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
});

export default PrintableAnswers;