import {
  Box,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Answers, Question } from '../../../../types';
import AnswerSelect from './AnswerSelect';
import OtherAnswerSelect from './OtherAnswerSelect';
import { parseAnswerText } from './utils';
import QuestionMedia from './QuestionMedia';

const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
  'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

type ContainerProps = {
  children: React.ReactNode;
};

const Container = ({ children }: ContainerProps) => (
  <Box sx={{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }}
  >
    {children}
  </Box>
);

type MultipleChoicesPanelProps = {
  question: Question;
  questions: Question[];
  setAnswers: (id: number, answer: string) => void;
  setOthers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent;
};

const MultipleChoicesPanel = ({
  question, setAnswers, answers, isCurrent, setOthers, questions,
}: MultipleChoicesPanelProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [other, setOther] = useState<string>('');
  const hasOther = question.mc_question?.mc_question_choice_other;

  const handleClick = (value: string) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((item) => item !== value));
    } else if (question.mc_question?.multi_select) {
      if (!selected.includes(value)) {
        setSelected([...selected, value]);
      }
    } else {
      setSelected([value]);
    }
  };

  useEffect(() => {
    if (answers[question.id] && selected !== answers[question.id].split(',')) {
      setSelected(answers[question.id].split(','));
    }
  }, [question]);

  useEffect(() => {
    setAnswers(question.id, selected.toString());

    if (hasOther && other !== '') {
      setOthers(question.id, other);
    }
  }, [selected, other]);

  return (
    <Container>
      <Typography sx={{ width: '100%', mb: 1 }}>
        <Markdown>
          {question.mc_question.mc_question_text}
        </Markdown>
      </Typography>
      <QuestionMedia
        mediaType={question.mc_question.question_media_type}
        mediaUrl={question.mc_question.question_media}
      />
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      >
        {ALPHABET.map((letter, index) => (
          question.mc_question[`mc_question_choice_${letter.toLowerCase()}`] && (
            <AnswerSelect
              key={letter}
              index={index}
              selected={selected.includes(letter)}
              setSelected={handleClick}
              value={letter}
              multiSelect={question.mc_question.multi_select}
              isCurrent={isCurrent}
            >
              {parseAnswerText(
                question.mc_question[`mc_question_choice_${letter.toLowerCase()}`],
                questions,
                answers,
              )}
            </AnswerSelect>
          )
        ))}
      </Box>
      {hasOther && (
        <OtherAnswerSelect
          selected={selected.includes('OTHER')}
          setSelected={handleClick}
          onChange={(val) => setOther(val)}
          multiSelect={question.mc_question.multi_select}
          isCurrent={isCurrent}
        />
      )}
    </Container>
  );
};

export default MultipleChoicesPanel;
